import React, {useEffect, useState} from "react";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import {Avatar, Button, Card, Col, Divider, Input, Form, Row, Typography} from "antd";
import ContentWrapper from "../Components/ContentWrapper";
import GetWhiteLabelData from "../Common/ApiCall/GetWhiteLabelData";

const {Title} = Typography
const {Meta} = Card

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 12
  }
}

const MyAccount = ({user}) => {
  const [accounts, setAccounts] = useState('')

  function changeText(word) {
    word = word.toUpperCase()
    if(word === "BASIC") return "Profilemate Frontend Product"
    else if(word === "PREMIUM") return "VIP Searches"
    else if(word === "PREMIUM_TRAINING") return "VIP Training"
    else if(word === "WHITELABEL") {
      return word+"-"+accounts
    } else return word
  }

  function getData() {
    GetWhiteLabelData()
      .then(r=>{
        setAccounts(r.accountsLeft)
      })
  }

  useEffect(()=>{
    if(user.permissions.indexOf("WHITELABEL")!==-1) {
      getData()
    }
  }, [])

  return(
    <PlainContentWrapper>
      <Row gutter={[24, 8]} align="center" >
        <Col span={24} lg={{span: 18}}>
          <ContentWrapper marginLess>
            <Title level={3} >
              Your Account Information!
            </Title>
            <Divider />

            <Form
              {...layout}
            >
              <Form.Item
                label={"Email"}
              >
                {user.email}
              </Form.Item>
              <Form.Item
                label={"User Type"}
              >
                {changeText(user.subscription)}
              </Form.Item>
              <Form.Item
                label="Detailed Calls Remaining"
              >
                {user.detailed_calls_remaining}
              </Form.Item>
              <Form.Item
                label={"Permissions"}
              >
                {
                  user.permissions.map((item, index)=>(
                    <span>
                      {index+1}. {changeText(item)} <br />
                    </span>
                  ))
                }
              </Form.Item>
              <Form.Item
                label={"Instagram Account"}
              >
                {user.instaUsername ? (
                  <Card style={{ width: 300, marginTop: 16 }}>
                    <Meta
                      avatar={
                        <Avatar
                          src={user.instaProfilePic}
                        />
                      }
                      title={user.instaUsername}
                    />
                  </Card>
                ):
                  <p>
                    No Account Associated
                  </p>
                }
              </Form.Item>
            </Form>
            <br />

          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default MyAccount