import React, {useEffect, useState} from 'react'
import {Row, Col, Card, Layout, Typography, Divider, Button, Input, Avatar, Spin} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import IFrameComponent, {IFrameComponentYoutube} from "../Components/IFrameComponent";
import image from '../Common/Images/chrome-button.png'
import ReactGA from 'react-ga'
import GetIgDataFromUsername from "../Common/ApiCall/GetIgDataFromUsername";
import ApiError from "../Common/Models/ApiError";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import UpdateIgId from "../Common/ApiCall/UpdateIgId";
import {CloseCircleOutlined} from "@ant-design/icons";

const {Title} = Typography
const {Meta} = Card

const chromeLink = "https://chrome.google.com/webstore/detail/profilemate/cpobppcoiojpficphicelljjejeogcpf"
const webSiteZipLink = "https://download.profilemate.com/"
const extensionLink = webSiteZipLink

const HomePage = ({userObj, reloadUserData}) => {
  const [username, setUsername] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged]  = useState(false)

  function Download() {
    ReactGA.event({
      category: 'Download Extension',
      action: 'Click Download Button'
    })
    return true
  }

  function removeUser() {
    setUser(null);
    setChanged(false)
  }

  async function makeDetailedIDFromUsernames (usernames) {
    setLoading(true)
    let userData = await GetIgDataFromUsername(username);
    if (userData instanceof ApiError) {
      if (userData.message === "User does not exist") {
        SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      } else {
        SendNotification(NotificationTypeEnum.Failure, 'Something went Wrong')
      }
      setLoading(false)
      return
    }
    UpdateIgId(userData.id, userData.username, userData.picture)
      .then(r => {
        ReactGA.event({
          category: 'Set Ig User',
          action: 'Ig User Added '+userData.username
        })
        if(r.success) {
          SendNotification(NotificationTypeEnum.Success, "Instagram User Updated")
          setUser(userData)
          reloadUserData()
        } else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)
        }
        setLoading(false)
      })
  }

  function onChangeUsersId (usersId){
    makeDetailedIDFromUsernames(usersId)
  };

  useEffect(()=>{
    if(userObj.instaId) {
      let instaUser = {
        username: userObj.instaUsername,
        id: userObj.instaId,
        picture: userObj.instaProfilePic
      }
      setUser(instaUser)
    }
  }, [])

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 16}}>
          <ContentWrapper marginLess>
            <Title style={{fontWeight: 'medium'}}>
              Welcome to ProfileMate
            </Title>
            <IFrameComponentYoutube url={"SGoqpmr6DzE"} autoplay/>
            {/*<Divider className="black-divider" />*/}
            {/*<IFrameComponent videoId={463371832} autoplay />*/}
            {/*<Divider className="black-divider" />*/}
            {/*<IFrameComponent videoId={456059990} />*/}
          </ContentWrapper>
        </Col>
        <Col md={{span: 24}} lg={{span: 8}} >
            <Title>
              STEP ONE
            </Title>
            <Divider className="black-divider" />
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <a
                href={extensionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  width="100%"
                  style={{padding: '12px'}}
                  src={image}
                  alt="Chrome Web Store"
                />
              </a>
            </div>
          <br />
          <br />
          <Title>
            STEP TWO
          </Title>
          <Divider className="black-divider" />
          <ContentWrapper>
            <Title level={2} >
              Instagram Account for Access
            </Title>
            <Divider className="black-divider" />
            {user ? (
              <Card style={{ width: 300, marginTop: 16 }} loading={loading}>
                <Meta
                  avatar={
                    <Avatar
                      src={user.picture}
                    />
                  }
                  title={user.username}
                />
                <CloseCircleOutlined
                  onClick={removeUser}
                />
              </Card>
            ): null}
            <br />
            {loading ? (
              <Spin />
            ) : null}
            {
              !user ? (
                <div>
                  <Input onPressEnter={onChangeUsersId} placeholder="Enter Username" size="large" onChange={e=>setUsername((e.target.value).trim())} />
                  <br />
                  <br />
                  <Button disabled={!username.length} onClick={onChangeUsersId} className="dark-button" size="large" block type="primary">
                    Submit
                  </Button>
                </div>
              ) : null}
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default HomePage