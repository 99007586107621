import React, {useEffect, useState} from "react";
import {Route, Redirect} from "react-router-dom";
import GetUserDetails from "../Common/ApiCall/GetUserDetails";

export const PrivateRoute = ({Component, isAuthed, user, setUser, ...rest}) => {

  return(
    <Route
      render={props => isAuthed ? (
        <Component user={user} setUser={setUser} />
      ) : (
        <Redirect to={{
          pathname: '/sign-in'
        }} />
      )}
    />
  )

/*  return(
    <Route
      {...rest}
      render={props =>
        isAuthed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in'
            }}
          />
        )
      }
    />
  )*/
}
