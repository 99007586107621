import React, {useState} from "react";
import {Button, Card, Typography, Col, Divider, Form, Input, Row, Modal, InputNumber} from "antd";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import RegisterUser from "../Common/ApiCall/RegisterUser";
import Sleep from "../Common/Utils/Sleep";
import UserLogWrapper from "../Components/UserLogWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import ContentWrapper from "../Components/ContentWrapper";
import {Redirect} from "react-router";
import Register from '../Common/Images/register.png'
import GetProfilemateTokenJvzoo from "../Common/ApiCall/GetProfilemateTokenJvzoo";

const {Title} = Typography

const layout = {
  wrapperCol: {
    lg: {
      span: 20,
      offset: 2
    }
  },
};
const tailLayout = {
  wrapperCol: {
    ld: {
      span: 20,
      offset: 2
    },
    md: {
      span: 24
    }
  },
};


const CollectionCreateForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const [info, setInfo] = useState(false)

  const getToken = (v) => {
    GetProfilemateTokenJvzoo(v.transactionId)
      .then(r => {
        if(r.success) {
          setInfo(r.data)
        } else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)
        }
      })
  }

  return (
    <Modal
      visible={visible}
      title="Get Profilemate Token"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={getToken}
        name="form_in_modal"
        hideRequiredMark
        initialValues={{
          modifier: 'public',
        }}
      >
        {
          info ? (
            <>
              {info.message}
            </>
          ) : (
            <>
              <Form.Item
                name="transactionId"
                label="Transaction Id"
                rules={[
                  {
                    required: true,
                    message: 'Please input the Jvzoo Transaction Id',
                  },
                ]}
              >
                <Input
                  placeholder="Enter JVZooTransaction Id"
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </>
          )
        }
      </Form>
    </Modal>
  );
};

const SignUp = ({isAuthed}) => {
  const [success, setSuccess] = useState(false)
  const [visible, setVisible] = useState(false)

  const onFinish = values => {
    if(values.password !== values.confirm_password) {
      SendNotification(NotificationTypeEnum.Success, 'Passwords doesn\'t match')
      return
    }
    RegisterUser(values.email, values.token, values.password)
      .then(r => {
        //console.log(r)
        if(r.success) {
          SendNotification(NotificationTypeEnum.Success , 'You have been Registered')
          Sleep(3000)
            .then(()=>{
              setSuccess(true)
            })
        }
        else {
          let error ='Registration Failed'
          if(r.errors[0].message) {
            error = r.errors[0].message
          }
          SendNotification(NotificationTypeEnum.Failure, error)
        }
      })
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  if(isAuthed || success) {
    return(
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    )
  }

  return(
    <UserLogWrapper>

      <Row align="center">

        <Col md={{span: 12}} lg={{span: 16}}>
          <PlainContentWrapper>

            <img
              width="80%"
              alt="image"
              src={Register}
            />

          </PlainContentWrapper>
        </Col>

        <Col md={{span: 8}} >
          <ContentWrapper>

            <Title>
              Sign Up
            </Title>

            <Divider />

            <Form
              {...layout}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                  noStyle
                >
                  <Input
                    size="large"
                    bordered={false}
                    placeholder="Email"
                    type="email"
                  />
                </Form.Item>
                <Divider className="black-divider" />
              </Form.Item>

              <Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      min: 8
                    },
                  ]}
                  noStyle
                >
                  <Input.Password
                    bordered={false}
                    placeholder="Password"
                    size="large"
                  />
                </Form.Item>
                <Divider className="black-divider" />
              </Form.Item>

              <Form.Item>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      min: 8,
                    },
                  ]}
                  noStyle
                >
                  <Input.Password
                    size="large"
                    bordered={false}
                    placeholder="Confirm Password"
                  />
                </Form.Item>
                <Divider className="black-divider" />
              </Form.Item>

              <Form.Item>
                <Form.Item
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the token!',
                    },
                  ]}
                  noStyle
                >
                  <Input
                    size="large"
                    bordered={false}
                    placeholder="Enter ProfileMate Token"
                    type="text"
                  />
                </Form.Item>
                <Divider className="black-divider" />
              </Form.Item>

              <Form.Item>
                <Card className="blue-card">
                  <p>
                    Enter the ProfileMate token you got on the mail
                  </p>
                </Card>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <div className="align-center">
                  <Button
                    size="large"
                    htmlType="submit"
                    type="primary"
                  >
                    Sign Up
                  </Button>
                </div>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <div className="align-center">
                  <Button
                    size="large"
                    onClick={()=>setVisible(true)}
                  >
                    Don't have Token?
                  </Button>
                </div>
              </Form.Item>
            </Form>

            <CollectionCreateForm
              visible={visible}
              onCancel={() => {
                setVisible(false);
              }}
            />
          </ContentWrapper>
        </Col>
      </Row>
    </UserLogWrapper>
  )
}

export default SignUp
