import React from "react";
import {Row, Col, Card, Typography, Divider, Button} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";

const {Meta} = Card
const {Title} = Typography

const details = [
  {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
    desc: 'This training wll go DEEP in how to take the information found in profilelmate adn convert this to sales in the bank. from ecom, to local marketing to affiliate marketing, this training ad 10 VIP webinar series will ensure your customers have the most success with profilemate.',
    name: 'VIP Training',
    title: <p>OTO 1 - $67<br />ONE TIME</p>,
    link: 'http://profilemate.com/vip'
  },
  {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
    desc: 'This upgrade will allow users to 10x their search results, essentially giving them 10 days of front end search results in a single day. this is the difference between 300 emails a day to 3 thousand. This upgrade also allows users to search hash tags AND location posts.',
    name: 'VIP Searches',
    title: <p>OTO 2<br />$47/MONTH</p>,
    link: 'https://profilemate.com/searches/'
  },
  {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
    desc: "This is a no brainer for any agency or local marketers who want to offer Profilemate to their clients as if it's made by them. This will allow users to create Profilemate front end licenses & add their clients in as if it's built by them",
    name: 'Whitelabel 10 Account Key',
    title: <p>OTO 3 <br />$197</p>,
    link: 'https://profilemate.com/whitelabel'
  },
  {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
    desc: "This is a no brainer for any agency or local marketers who want to offer Profilemate to their clients as if it's made by them. This will allow users to create Profilemate front end licenses & add their clients in as if it's built by them",
    name: 'Whitelabel 50 Account Key',
    title: <p>OTO 4 <br />$497</p>,
    link: 'https://profilemate.com/whitelabel'
  }
]

const Upsell = () => {
  return(
    <ContentWrapper>
      <Title>
        VIP Upgrades
      </Title>
      <Divider />
      <Row gutter={[24, 16]} justify="space-around" >
        {details.map((item, index)=>(
          <Col md={{span: 12}} lg={{span: 6}} key={index} >
            <Card
              className="upsell-card"
/*              cover={
                <img
                  alt="example"
                  src={item.img}
                />
              }*/
            >
              <div className="align-center">
                <Title className="upsell-title" >
                  {item.title}
                </Title>
                <Title level={3}>
                  {item.name}
                </Title>
                <p>
                  {item.desc}
                </p>
                <br />
                <Button size="large" block type="primary dark-button" href={item.link} target="_blank">
                  Buy
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </ContentWrapper>
  )
}

export default Upsell