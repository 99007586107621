import React from "react";
import UserLogWrapper from "../Components/UserLogWrapper";
import {Button, Col, Row, Typography} from "antd";
import ContentWrapper from "../Components/ContentWrapper";
import {Link, Redirect} from "react-router-dom";

const {Title} = Typography

const NotFound = () => {

  return(
    <Redirect
      to={'/'}
    />
  )

  return(
    <UserLogWrapper>

      <Row align="center">

        <Col md={{span: 16}} >
          <ContentWrapper style={{height: '80%'}} className="align-center">

            <Title>
              Page Not Found
              <br />
              You have reached an unknown Page
              <br />
              Go Back
              <Button>
                <Link to={'/'}>
                  Home
                </Link>
              </Button>
            </Title>

          </ContentWrapper>
        </Col>
      </Row>
    </UserLogWrapper>
  )
}

export default NotFound