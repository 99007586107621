import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";

const RegisterUser = async (email, token, password) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest("https://api.profilemate.com/api/v1/register", {
      password,
      email,
      token
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  //console.log(apiResponse)
  if(apiResponse.body) {
    return apiResponse.body
  } else {
    return apiResponse.error
  }
/*  if (apiResponse.isValid()) {
    return apiResponse.body;
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${apiResponse.error}`;
    return new ApiError(message);
  }*/
};

function dummyPlans() {
  return {
    "data": {
      "message": "User Registered",
    },
    success: true,
    errors: []
  }
}

function ErrorMessage() {
  return {"data":null,"success":false,"errors":[{"code":422,"message":"Invalid Token"}]}
}

export default RegisterUser
