import React, {useEffect, useState} from "react";
import {Divider, Typography, Row, Col, Button} from "antd";
import ContentWrapper from "../Components/ContentWrapper";
import IFrameComponent, { IFrameComponentYoutube } from "../Components/IFrameComponent";
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import {wpBlogs} from "../Common/ApiCall/GetWordpressBlogs";

const {Title} = Typography

const data = [
  {
    text: 'Welcome to Chrome Tool',
    vimeoID: '456061276'
  },
  {
    text: 'Webinar 1',
    vimeoID: 466061591
  },
  {
    text: 'Webinar 2',
    link: '2PNO-imRftE'
  },
  {
    link: 'pQrFTDgsXZs',
    text: 'Webinar 3'
  },
  {
    link: 'wl84dMSyh4U',
    text: 'Webinar 4'
  },
  {
    link: 'WGl-4Wf5-14',
    text: 'Webinar 5'
  },
  {
    vimeoID: '799354524?h=1878ea5473',
    text: 'AI ChatGpt and Profilemate'
  },
  {
    link: 'KBKtNmdjpSs',
    text: 'How to reach out COLD EMAIL TACTICS sheet and Top 3 Ways to Make Money Online in 2020'
  },
  {
    text: 'ProfileMate User Extractor',
    vimeoID: '456060883'
  },
  {
    text: 'Email Extractor | Detailed User Analysis',
    vimeoID: '456060485'
  },
  {
    text: 'Pin Profilemate Extension to Chrome tab',
    vimeoID: '456059922'
  },
  {
    text: 'HOW TO UPLOAD EMAILS & WHAT AUTORESPONDER TO USE',
    vimeoID: 464066058
  }
]

const Training = () => {
  const [blogs, setBlogs ] = useState([])

  useEffect(()=>{
    seedBlogs()
  }, [])

  function seedBlogs() {
    setBlogs(wpBlogs.posts)
  }

  function getBlogs() {
    axios
      .get(
        "http://public-api.wordpress.com/rest/v1/sites/profilemate.wordpress.com/posts"
      )
      .then(res => {
        setBlogs(res.data.posts)
      })
      .catch(error => console.log(error));
  }

  return(
    <ContentWrapper>
      <Row gutter={[24, 24]} align="center" >
        <Col sm={{span: 24}} className="align-center" >
          <Title >
            Training
          </Title>
          <Divider className="black-divider" />
        </Col>
        <Col sm={{span: 24}} lg={{span: 16}} className="is-marginless">
          {data.map((item, index)=>(
            <>
              <Title level={3} style={{margin: '1rem'}} key={index} >
                {item.text}
              </Title>
              {item.vimeoID ? (
                <IFrameComponent videoId={item.vimeoID} key={index} />
              ): (
                <IFrameComponentYoutube url={item.link} key={index} />
              )}
              <br />
            </>
            ))}
            <br />
          <br />
          <Title level={3} style={{margin: '1rem'}}>
            BONUS HIGH TICKET TRAINING (my high ticket audience pay 297/mo for these weekly trainings that you get for free)
          </Title>
          <br />
          <Title level={3} style={{margin: '1rem'}}>
            Working with Celebrities to gow 6 figure fan pages in 7 days (huge bonus - 997 high ticket course)
          </Title>
          <IFrameComponent videoId={463364166} />
          <Title style={{margin: '1rem'}} level={3}>
            Get access FOR FREE
            <Button type="link" size="large" href={"https://local-social-academy.teachable.com/purchase?product_id=1627041"} >
              here
            </Button>
          </Title>
          <br />
          <Divider className="black-divider" />
          <Title level={2}>
            Tips and Tricks
          </Title>
          <br />
          {blogs.map((item, index) => (
            <>
              <a href={item.URL} target="_blank" >
                <Title className="link-hover" level={3} key={index} >
                  {item.title}
                </Title>
              </a>
              <p>
                {ReactHtmlParser(item.excerpt)}
              </p>
              <Button target="_blank" type="link" size="large" href={item.URL} >
                Continue Reading
              </Button>
              <br />
              <br />
            </>
          ))}
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default Training