import { GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError";
import authHeader from "./auth-header";

const GetWhiteLabelData = async () => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest("https://api.profilemate.com/api/v1/whitelabel/get_all", {}, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.isValid()) {
    return apiResponse.body.data;
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${apiResponse.error}`;
    return new ApiError(message);
  }
};

function dummyPlans() {
  return {
    data: {
      "accountsLeft": 9,
      "accounts": [
        {
          "id": "2134232",
          "profilePic": "https://randomuser.me/api/portraits/thumb/men/75.jpg",
          "username": "jane",
          "createdAt": "2020-09-04T17:30:27.000Z"
        },
        {
          "profilePic": "https://randomuser.me/api/portraits/thumb/men/75.jpg",
          "username": "joan",
          "id": "21313222",
          "createdAt": "2020-09-04T17:30:27.000Z"
        },
        {
          "profilePic": "https://randomuser.me/api/portraits/thumb/men/75.jpg",
          "username": "jaoane",
          "id": "21342213",
          "createdAt": "2020-09-04T17:30:27.000Z"
        }
      ]
    },
    success: true,
    errors: []
  }
}

export default GetWhiteLabelData
