import React from "react";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import {Collapse, Typography} from "antd";
import FaqComponent from '../Faq/FaqComponent'

const {Panel} = Collapse
const {Title, Link} = Typography

const FaqData = [
  {
    question: 'Does it work with Mac & Windows both?',
    answer: 'ProfileMate is a chrome extension. It means that all you need is chrome for it to work irrespective of whether you are on Mac or Windows or Linux. Just install the ProfileMate extension from the Google Chrome store, set the instagram account for access and you are good to go!'
  },
  {
    question: 'What do I get in the VIP searches Upsell?',
    answer: 'Without this, we discover the data for you from your IP. Means, there is a limit on data and the speed by which we get the data everyday. With the Upsell, we redirect all the traffic from thousands of IPs at our end and get you much more data and about 3-4 times fast than normal'
  },
  {
    question: 'Can I get Email for Every user from Instagram?',
    answer: 'Not every user puts up their email. If they have an email / contact button or have it in their bio, then we get it for you. Normally this is about 10-15% depending on the genre.'
  },
  {
    question: 'How many Emails can I get out of 10,000 users?',
    answer: 'This depends a lot on the genre and type of business and the way the data is extractor (hashtags or liker, commenters of a post). On an average you can get 7-35% take away rate. So for 10,000 users you can get about 700 - 3500 emails.'
  },
  {
    question: 'In what format is the data downloaded?',
    answer: 'We give you data in CSV sheet. You can open it with Excel or with Google Sheets. Click here (https://www.tillerhq.com/how-to-import-csv-into-a-google-spreadsheet/) for tutorial on how to see data with Google Sheet.'
  },
  {
    question: 'How to use this data?',
    answer: 'Data from Instagram is highly targeted. People who like a post means they are engaged in that genre. We get you their emails and contacts which you can use to email/message them. You can also use this data to find the perfect micro size influencers for your business. You can use this data to run targeted Facebook ads to make the perfect look alike audience and save initial free days money just to reach the right segment.'
  }
]

const webinarLink = "https://socialunderworld.freshdesk.com/support/tickets/new"

const FAQ = () => {
  return(
    <PlainContentWrapper>
      <Title level={3}>
        Check FAQs before raising a support ticket
      </Title>
{/*       <Collapse
        accordion
        bordered
        ghost
        className="site-collapse-custom-collapse"
      >
        {FaqData.map((item, index)=>(
          <Panel header={item.question} key={index} className="site-collapse-custom-panel">
            <p style={{color: 'white', fontWeight: 'bold'}}>{item.answer}</p>
          </Panel>
        ))}
      </Collapse> */}
      <FaqComponent 
        columns={1}
        siteAddress={'app.profilemate.com'}
        grouped={false}
      />
      <Title level={3} >
        Still have a doubt, <Link href={webinarLink} target="_blank">Raise Query</Link>
      </Title>
    </PlainContentWrapper>
  )
}

export default FAQ