import React from "react";
import ContentWrapper from "../Components/ContentWrapper";
import {Typography, Col, Row, Divider, Button} from "antd";
import IFrameComponent, {IFrameComponentYoutube} from "../Components/IFrameComponent";

const data = [
  {
    link: 463303778,
    text: 'VIP Training Members Welcome + Special Bonus',
  },
  {
    link: 464071033,
    text: 'Knowing Your Audience & Finding Them On Instagram',
  },
  {
    link: 463304401,
    text: 'How to Reach OUT to your Audience',
  },
  {
    link: 463327303,
    text: 'Finding The Most ENGAGED Users With Profilemate',
  },
  {
    text: 'Profilemate with Ecommerce',
    link: 463338415
  },
  {
    text: 'Profilemate With Local Marketing',
    link: 463363794
  },
  {
    text: 'Profilemate With Affiliate Marketing',
    link: 463338029
  }
]

const appendSection1 = [
  {
    text: 'Segment influencers, business’s & accounts with emails only',
    link: 464065889
  },
  {
    text: 'HOW TO UPLOAD EMAILS & WHAT AUTORESPONDER TO USE',
    link: 464066058
  }
]

const appendSection2 = [
  {
    text: 'Filter out emails or numbers only',
    link: 464065649
  },
  {
    text: 'UNBRANDED WHITELABEL/RESELLER account',
    link: 464066307
  },
  {
    text: 'Defining your market & how to find them',
    link: 464071033
  }
]

const youtubeLinks = [
  {
    text: 'Top 3 ways to make money in 2020 online',
    link: 'KBKtNmdjpSs'
  },
  {
    text: 'Instagram growth masterclass with Ecommerce',
    link: 'TIFT5vyqp44'
  },
  {
    text: 'Affiliate marketing domination in 2020',
    link: '4H4RnuqnEIA'
  },
  {
    text: 'How to get the blue tick on Instagram & FB (Verification)',
    link: 'JcIh8qNW2yE'
  }
]

const {Title} = Typography

const PremiumTraining = () => {
  return(
    <ContentWrapper>
      <Row gutter={[24, 24]} align="center">
        <Col sm={{span: 24}} className="align-center" >
          <Title >
            VIP Training Members
          </Title>
          <Divider className="black-divider" />
        </Col>
        <Col sm={{span: 24}} lg={{span: 16}} className="is-marginless">
          <Title level={3} className="align-center " >
            Hey team! Welcome to the VIP Founders Training + Webinar series.
            This is where i work with YOU personally to get you results the fastest way possible.
            The most important part of this is to be signed up to the VIP TRAINING ONLY WEBINAR series. You can do so here -
            <Button size="large" type="link" href={"https://attendee.gotowebinar.com/rt/4316607194319330063"} >https://attendee.gotowebinar.com/rt/4316607194319330063</Button>
          </Title>
          <br />
          <Title level={3} className="align-center "  >
            This is where i will work with YOU on live examples each week (multiple times a week in certain weeks) to give you real live examples of niches you will work in (vs the cliche ‘dog training’ examples that i am guilty of showing).
          </Title>
          <Title level={3} className="align-center ">
            Oh yeah and to say thank you for picking up the VIP training i’m giving you my high ticket instagram
            growth course for free - check the video below on that ;)
            Le’ts get started!
          </Title>
          <br />
          <Title level={3} className="align-center ">
            This training here will get you set UP for the webinars & I want you to go through these and implement so when we start the webinar training on the 7th of October you will have a LIST ready to go - i’ll be adding further videos here each day that are already set up to be drip fed to your email (and added here daily) - if you action this you WILL get results.
          </Title>
          <br />
          {data.map((item,index)=>(
            <>
              <Title level={3} key={index} style={{margin: '1rem'}}>
                {item.text}
              </Title>
              <IFrameComponent key={index} videoId={item.link} />
              <br />
            </>
          ))}
          <br />
          <br />
          <Title level={3} style={{margin: '1rem'}}>
            BONUS HIGH TICKET TRAINING (my high ticket audience pay 297/mo for these weekly trainings that you get for free)
          </Title>
          <br />
{/*          <Title level={3} style={{margin: '1rem'}}>
            Link -
          </Title>
          <br />*/}
          <Title level={3} style={{margin: '1rem'}}>
            Working with Celebrities to gow 6 figure fan pages in 7 days (huge bonus - 997 high ticket course)
          </Title>
          <IFrameComponent videoId={463364166} />
          <Title  style={{margin: '1rem'}} level={3}>
            Get access FOR FREE
            <Button type="link" size="large" href={"https://local-social-academy.teachable.com/purchase?product_id=1627041"} >
              here
            </Button>
          </Title>
          <br />
          {appendSection1.map((item,index)=>(
            <>
              <Title level={3} key={index} style={{margin: '1rem'}}>
                {item.text}
              </Title>
              <IFrameComponent key={index} videoId={item.link} />
              <br />
            </>
          ))}
          <br />
          <Title level={3} style={{margin: '1rem'}}>
            Make sure to be on the welcome monteisation & email domination webinar
            <Button type="link" size="large" href={"https://attendee.gotowebinar.com/rt/4316607194319330063"} >
              HERE
            </Button>
          </Title>
          <br />
          {appendSection2.map((item,index)=>(
            <>
              <Title level={3} key={index} style={{margin: '1rem'}}>
                {item.text}
              </Title>
              <IFrameComponent key={index} videoId={item.link} />
              <br />
            </>
          ))}
          <br />
          {youtubeLinks.map((item, index)=>(
            <>
              <Title key={index} level={3} style={{margin: '1rem'}} >
                {item.text}
              </Title>
              <IFrameComponentYoutube url={item.link} />
              <br />
            </>
          ))}
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default PremiumTraining