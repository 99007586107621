import React, {useEffect, useState} from "react";
import Dashboard from "../Dashboard";
import {Route, Switch} from "react-router-dom";
import SignIn from "../SignIn";
import {PrivateRoute} from "../Components/PrivateRoute";
import GetUserDetails from "../Common/ApiCall/GetUserDetails";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import ForgotPassword from "./ForgotPassword";
import ReactGA from 'react-ga'
import SignUp from "../SignIn/SignUp";
import GetTokenJvzoo from "../SignIn/GetTokenJvzoo";
import NotFound from "./NotFound";

const Home = () => {
  const [user, setUser] = useState(null);
  function handleAuthed() {
    if(user) {
      setIsAuthed(true);
    }
  }

  const [isAuthed, setIsAuthed] = useState(false)

  function getUserDetails() {
    let token = localStorage.getItem('authToken');
    if(token) {
      GetUserDetails(token)
        .then(r => {
          if(r.success) {
            setUser(r.data)
          }
          else {
            SendNotification(NotificationTypeEnum.Failure, 'Bad Credentials')
          }
        })
    }
  }

  useEffect(()=> {
    getUserDetails()
    ReactGA.initialize('UA-177308350-2', {
      debug: false
    })
  }, [])

  useEffect(()=>handleAuthed())

  return(
    <div>
      <Switch>

        <PrivateRoute
          path="/"
          exact
          user={user}
          setUser={getUserDetails}
          isAuthed={isAuthed}
          Component={Dashboard}
        />

        <Route
          path="/sign-in"
          exact
        >
          <SignIn
            isAuthed={isAuthed}
            setUser={getUserDetails}
          />
        </Route>

        <Route
          path="/sign-up"
          exact
        >
          <SignUp
            isAuthed={isAuthed}
          />
        </Route>

        <Route
          path="/validate/:id"
          exact
        >
         <ForgotPassword />
        </Route>

        <Route
          path="/get-profilemate-token"
          exact
        >
          <GetTokenJvzoo />
        </Route>

        <Route>
          <NotFound />
        </Route>

      </Switch>
    </div>
  )
}

export default Home