import React from "react";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import image from '../Common/Images/purchase-image.png'
import {Col, Row, Typography, Button} from 'antd'

const {Title} = Typography

const Purchase = ({title, url}) => {
  title = title.split("_").join(" ")
  return(
    <PlainContentWrapper>
      <Row gutter={24} justify="center">
        <Col md={{span: 20}} lg={{span: 10}} >
          <div className="align-center">
            <img
              width="100%"
              src={image}
              alt="Image"
            />
            <Title>
              Uh Oh!
            </Title>
            <Title level={3} style={{fontWeight: 'light'}}>
              To access {title}, you need to purchase its subscription below.
            </Title>
            <Button href={url} target="_blank" type="primary" size="large" className="dark-button">
              Buy Now
            </Button>
          </div>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default Purchase