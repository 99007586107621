import { GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError";
import authHeader from "./auth-header";

const GetUserDetails = async (token) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest("https://api.profilemate.com/api/v1/me", {}, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }

  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {"data":{"id":9,"email":"2000.aman.sinha@gmail.com","permissions":["PREMIUM_TRAINING","WHITELABEL"],"subscription":"BASIC","free_calls_limit":500,"detailed_calls_remaining":0,"instaId":"5894368402","instaUsername":"_a_gryffindor","instaProfilePic":"https://instagram.fbom12-1.fna.fbcdn.net/v/t51.2885-19/s150x150/97155133_606089819995191_1827690313104228352_n.jpg?_nc_ht=instagram.fbom12-1.fna.fbcdn.net&_nc_ohc=MatiALr1OxEAX9exl7Q&oh=b0ef9c6558a911e9993e1d4c103d498f&oe=5F9ABD03"},"success":true,"errors":[]}
}

export default GetUserDetails
