import React from 'react';
import './Components/all.sass'
import Home from "./Home";
import ErrorBoundary from "./Common/Components/ErrorBoundary";

function App() {
  const WrapperHome = (props) => {
    return(
      <ErrorBoundary>
        <Home {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <WrapperHome />
  );
}

export default App;
