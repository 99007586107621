import React from "react";
/*import Vimeo from "react-vimeo";*/
import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from 'react-player'

export const IFrameComponentYoutube = ({url}) => {
  if(!url) url = "K9mNaZRDhUk";
  const embed_url = "https://www.youtube.com/embed/" + url
  return(
    <div className="iframe-container">
      <iframe
        width="560px"
        height="315px"
        src={embed_url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

const IFrameComponent = ({videoId, autoplay = true}) => {
  if(!videoId) videoId = '463338029' // ''

/*  return(
    <ReactPlayer
      url={"https://vimeo.com/"+videoId}
    />
  )*/

  return (
    <div className="iframe-container">
      <iframe
        src={"https://player.vimeo.com/video/"+videoId}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )

  return(
    <div className="iframe-container">
      <Vimeo
        video={parseInt(videoId)}
        autoplay={autoplay}
      />
    </div>
  )
}

export default IFrameComponent
