import React, {useEffect, useState} from "react";
import {Layout, Menu, Button, BackTop} from "antd";
import logo from '../Common/Images/logo-full.png'
import logoOnly from '../Common/Images/logo-only.png'
import {
  UserOutlined,
  VideoCameraOutlined,
  AppstoreAddOutlined,
  UploadOutlined, TagsOutlined,
  QuestionOutlined, HomeOutlined
} from '@ant-design/icons';
import HomePage from "./HomePage";
import Upsell from "./Upsell";
import Training from "./Training";
import isMobile from "is-mobile";
import PremiumTraining from "./PremiumTraining";
import FAQ from "./FAQ";
import logout from "../Common/ApiCall/Logout";
import WhiteLabel from "./WhiteLabel";
import Purchase from "./Purchase";
import MyAccount from "./MyAccount";

const {Header, Sider, Content} = Layout

const items = [
  {
    key: 1,
    icon: <HomeOutlined />,
    title: "Home"
  },
  {
    key: 2,
    icon: <UserOutlined />,
    title: "My Account"
  },
  {
    key: 3,
    icon: <UploadOutlined />,
    title: "Training"
  },
  {
    key: 4,
    icon: <VideoCameraOutlined />,
    title: "Premium Training"
  },
  {
    key: 5,
    icon: <AppstoreAddOutlined />,
    title: "VIP Upgrades"
  },
  {
    key: 6,
    icon: <TagsOutlined />,
    title: "Reseller License"
  },
  {
    key: 7,
    icon: <QuestionOutlined />,
    title: "FAQs"
  }
]

const Dashboard = ({user, setUser}) => {
  const [collapsed, toggleCollapsed] = useState(false)
  const [key, setKey] = useState(1)

  function toggle() {
    toggleCollapsed(!collapsed);
  }

  function toggleOnMobile() {
    if(isMobile()) {
      toggleCollapsed(true);
    }
  }

  function IsPermitted(pageTitle, url, component) {
    let z = false
    if(user.permissions) {
      z = user.permissions.indexOf(pageTitle.toUpperCase())!==-1
    }
    return z ? component : <Purchase title={pageTitle} url={url} />
  }

  function component() {
    switch (key) {
      case 1: return <HomePage reloadUserData={setUser} userObj={user}/>
      case 2: return <MyAccount user={user} />
      case 3: return <Training />
      case 4: return IsPermitted("Premium_Training", "http://profilemate.com/vip", <PremiumTraining />)
      case 5: return <Upsell />
      case 6: return IsPermitted("Whitelabel", "https://profilemate.com/whitelabel", <WhiteLabel />)
      case 7: return <FAQ />
      default: return <HomePage user={user} setKey={setKey} />
    }
  }

  //console.log('child', user)

  useEffect(()=>toggleOnMobile(), [])

  let image = collapsed ? logoOnly : logo

  return(
    <Layout className="is-fullheight">

      <BackTop />

      <Sider collapsible collapsed={collapsed} onCollapse={toggle} >

        <div className="logo">
          <img
            className="logo-img"
            alt="Logo"
            src={image}
          />
        </div>

        <Menu theme="dark" selectedKeys={String(key)} mode="inline" onClick={(key)=>setKey(parseInt(key.key))} defaultSelectedKeys={['1']}>
          {items.map((item)=>(
            <Menu.Item key={item.key} icon={item.icon}>
              {item.title}
            </Menu.Item>
          ))}
        </Menu>

      </Sider>

      <Layout className="site-layout">

        <Header className="header" style={{backgroundColor: 'white'}}>

          <Menu mode="horizontal" style={{float: 'right'}} >
            <Menu.Item key="1">
              <Button
                block
                href={"https://attendee.gotowebinar.com/register/6602071855686422800"}
                target="_blank"
              >
                Webinar
              </Button>
            </Menu.Item>
            <Menu.Item key="2">
              <Button
                block
                onClick={()=>setKey(7)}
              >
                Support
              </Button>
            </Menu.Item>
            <Menu.Item key="3">
              <Button block onClick={()=>logout()} type="primary" className="dark-button">
                Log out
              </Button>
            </Menu.Item>
          </Menu>

        </Header>

        {component()}

      </Layout>

    </Layout>
  )
}

export default Dashboard
