import React, {useState} from "react";
import UserLogWrapper from "../Components/UserLogWrapper";
import {Button, Col, Divider, Form, Input, Row, Typography} from "antd";
import ContentWrapper from "../Components/ContentWrapper";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import GetProfilemateTokenJvzoo from "../Common/ApiCall/GetProfilemateTokenJvzoo";

const {Title} = Typography

const GetTokenJvzoo = () => {
  const [info, setInfo] = useState(null)

  const getToken = (v) => {
    GetProfilemateTokenJvzoo(v.transactionId)
      .then(r => {
        if(r.success) {
          setInfo(r.data)
        } else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)
        }
      })
  }

  const onFinishFailed = errorInfo => {
    SendNotification(NotificationTypeEnum.Failure, errorInfo);
  };

  return(
    <UserLogWrapper>

      <Row align="center">

        <Col md={{span: 16}} >
          <ContentWrapper style={{height: '80%'}}>

            <Title level={2}>
              Get Profilemate Token From Jvzoo Details
            </Title>

            <Divider />

            <Form
              layout="vertical"
              onFinish={getToken}
              name="form_in_modal"
              initialValues={{
                modifier: 'public',
              }}
            >
              {
                info ? (
                  <>
{/*                    <Form.Item
                      label="Transaction Id"
                    >
                      <Input
                        disabled
                        value={info.transactionId}
                        placeholder="Enter JVZooTransaction Id"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Status"
                    >
                      {info.status}
                    </Form.Item>*/}
                    {info.message}
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="transactionId"
                      label="Transaction Id"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the Jvzoo Transaction Id',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter JVZooTransaction Id"
                      />
                    </Form.Item>
                    <Form.Item>
                      <div className="align-center">
                        <Button size="large" htmlType="submit">
                          Get Token
                        </Button>
                      </div>
                    </Form.Item>
                  </>
                )
              }
            </Form>

          </ContentWrapper>
        </Col>
      </Row>
    </UserLogWrapper>
  )
}

export default GetTokenJvzoo