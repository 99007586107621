import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";

const GetCustomerDetails = async (email, password) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest("https://api.profilemate.com/api/v1/login", {
      email,
      password
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNTk3NzM2NzExLCJleHAiOm51bGx9.ozZkk3wZjzCEwBlq_dSbonnLT2e4Hs7F9Kve4RKbFps"
    },
    "success": true,
    "errors": []
  }
}

export default GetCustomerDetails
