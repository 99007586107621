import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";

const GetProfilemateTokenJvzoo = async (transactionId) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest("https://api.profilemate.com/api/v1/transaction_status", {
      transactionId
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": {
      "transactionId": "tid",
      "email": "user@gmail.com",
      "product": "BASE",
      "status": "TOKEN_NOT_CLAIMED",
      "token": "xxxxxxxx-xxxxxxxx-xxxxxxxx",
      "claimedAt": null,
      "message": "It is done"
    },
    "success": true,
    "errors": []
  }
}

export default GetProfilemateTokenJvzoo
