import React, {useState} from "react"
import {Form, Input, Button, Checkbox, Row, Col, Typography, Divider} from 'antd';
import ContentWrapper from "../Components/ContentWrapper";
import SignInForm from "./SignInForm";
import UserLogWrapper from "../Components/UserLogWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import SignInImage from '../Common/Images/login.png'
import {Redirect} from "react-router";

const {Title} =Typography

const SignIn = ({setUser, isAuthed}) => {

  if(isAuthed) {
    return(
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    )
  }

  return(
    <UserLogWrapper>

      <Row align="center">

        <Col md={{span: 12}} lg={{span: 16}}>
          <PlainContentWrapper>

            <img
              width="80%"
              alt="image"
              src={SignInImage}
            />

          </PlainContentWrapper>
        </Col>

        <Col md={{span: 8}} >
          <ContentWrapper>

            <Title>
              Sign In
            </Title>

            <Divider />

            <SignInForm
              setUser={setUser}
            />
          </ContentWrapper>
        </Col>
      </Row>
    </UserLogWrapper>
  )
}

export default SignIn