import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError";
import authHeader from "./auth-header";

const UpdateIgId = async (id, username, profilePic) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest("https://api.profilemate.com/api/v1/update_ig", {
      id, username, profilePic
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": {},
    "success": true,
    "errors": []
  }
}

function Failed() {
  return {
    "data": {},
    "success": false,
    "errors": [
      {
        "message": "Bad Request"
      }
    ]
  }
}

export default UpdateIgId
